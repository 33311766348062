import React, { useState, useEffect } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import { Link } from 'react-router-dom';
import html2canvas from "html2canvas";
import Papa from 'papaparse'; // Import PapaParse
import * as XLSX from 'xlsx'; // Import XLSX
import "./InvoiceGenerator.css"; // Make sure to include your CSS file

const BulkImport = () => {
  const generateInvoiceNumber = () => {
    return `INV-${Math.floor(Math.random() * 90000) + 10000}`;
  };
  const [invoices, setInvoices] = useState([]); // Your invoices
    const [pdfs, setPdfs] = useState([]); // State to store generated PDFs

    const generateInvoice = async (e) => {
        e.preventDefault();
        // Assuming you have logic here to populate the invoices state
        await generateAndStoreAllInvoices();
    };

    const generateAndStoreAllInvoices = async () => {
      console.log("Invoices to generate:", invoices);
  
      if (invoices.length === 0) {
          console.error("No invoices to generate.");
          return;
      }
  
      const pdfPromises = invoices.map((invoice, index) => {
          console.log(`Generating PDF for invoice at index: ${index}`);
          return generatePDF(invoice, index); // Pass the invoice and index
      });
  
      try {
          const generatedPdfs = await Promise.all(pdfPromises);
          setPdfs(generatedPdfs); // Store generated PDFs in state
      } catch (error) {
          console.error("Error generating one or more PDFs:", error);
      }
  };

const generatePDF = async (invoice, index) => {
    if (!invoice || !invoice.invoiceDate) {
        console.error(`Invalid invoice data for index: ${index}`);
        return;
    }

    const formattedDate = invoice.invoiceDate.toISOString().split('T')[0];
    
    // Generate invoice number if not provided
    const invoiceNumber = invoice.invoiceNumber || generateInvoiceNumber();
    
    // Set the invoice number in the invoice object for consistency
    invoice.invoiceNumber = invoiceNumber; // Ensure it is the same for both preview and filename

    const filename = `${invoice.toCompany}-${formattedDate}-${invoiceNumber}.pdf`;

    let input = document.getElementById(`invoice-output-${index}`);

    // Retry logic: Check for the element a few times before giving up
    let attempts = 5; // Number of attempts to wait for the element
    while (!input && attempts > 0) {
        await new Promise((resolve) => setTimeout(resolve, 200)); // Wait 200ms before checking again
        input = document.getElementById(`invoice-output-${index}`);
        attempts--;
    }

    if (!input) {
        console.error(`Invoice element not found for index: ${index} after multiple attempts.`);
        return;
    }

    // Capture the canvas
    const canvas = await html2canvas(input, {
        scale: 2, // Adjust scale for quality/size
        useCORS: true,
        logging: false,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.7); // Compress image to JPEG with 70% quality

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
    
    // Check PDF size and adjust if necessary
    const pdfBlob = pdf.output("blob");
    if (pdfBlob.size > 5 * 1024 * 1024) { // If larger than 5MB
        console.warn(`PDF size exceeds 5MB. Consider reducing image quality or dimensions.`);
        // Implement additional logic to reduce size, e.g., adjusting quality or dimensions further
    }

    pdf.save(filename);
};
    const downloadPDF = (pdfBlob, index) => {
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `invoice-${index}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Clean up the URL
    };

    // Existing state declarations
    // const [toCompany, setToCompany] = useState("");
    // const [shipToCompany, setShipToCompany] = useState("");
    
    // Declare state for company details
    const [toCompanyDetails, setToCompanyDetails] = useState({
      address: "",
      gst: "",
      email: "",
      phone: ""
    });
  
    const [shipToCompanyDetails, setShipToCompanyDetails] = useState({
      address: "",
      gst: "",
      email: "",
      phone: ""
    });
    const [bulkItems, setBulkItems] = useState([]); // State to hold bulk imported items

  
//  const handleFileChange = (event) => {
//   const file = event.target.files[0];
//   if (!file) return;

//   const fileType = file.type;

//   if (fileType === 'text/csv') {
//       // Handle CSV file
//       Papa.parse(file, {
//           header: true,
//           complete: (results) => {
//               const allInvoices = results.data.map((row) => {
//                   const invoiceDate = new Date(row.invoiceDate);
//                   return {
//                       fromCompany: row.fromCompany || "",
//                       fromDetails: row.fromDetails ? row.fromDetails.split('|').map(detail => detail.trim()) : [],
//                       toCompany: row.toCompany || "",
//                       toDetails: row.toDetails ? row.toDetails.split('|').map(detail => detail.trim()) : [],
//                       shipToCompany: row.shipToCompany || "",
//                       shipToDetails: row.shipToDetails ? row.shipToDetails.split('|').map(detail => detail.trim()) : [],
//                       bankDetails: {
//                           bankName: row.bankName || "",
//                           accountNumber: row.accountNumber || "",
//                           ifsc: row.ifsc || "",
//                           accountHolder: row.accountHolder || ""
//                       },
//                       invoiceDate: isNaN(invoiceDate.getTime()) ? new Date() : invoiceDate, // Fallback to current date if invalid
//                       items: [{
//                           itemName: row.itemName || "",
//                           variety: row.variety || "",
//                           hsn: row.hsn || "",
//                           quantity: parseInt(row.quantity) || 0,
//                           price: parseFloat(row.price) || 0,
//                           unit: row.unit || "pcs"
//                       }]
//                   };
//               });

//               setInvoices(allInvoices); // Set the invoices state
//           },
//           error: (error) => {
//               console.error("Error reading CSV file:", error);
//           }
//       });
//   } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
//       // Handle Excel file
//       const reader = new FileReader();
//       reader.onload = (e) => {
//           const binaryStr = e.target.result;
//           const workbook = XLSX.read(binaryStr, { type: 'binary' });
//           const firstSheetName = workbook.SheetNames[0];
//           const worksheet = workbook.Sheets[firstSheetName];
//           const jsonData = XLSX.utils.sheet_to_json(worksheet);

//           const allInvoices = jsonData.map((row) => {
//               const invoiceDate = new Date(row.invoiceDate);
//               return {
//                   fromCompany: row.fromCompany || "",
//                   fromDetails: row.fromDetails ? row.fromDetails.split(',').map(detail => detail.trim()) : [],
//                   toCompany: row.toCompany || "",
//                   toDetails: row.toDetails ? row.toDetails.split(',').map(detail => detail.trim()) : [],
//                   shipToCompany: row.shipToCompany || "",
//                   shipToDetails: row.shipToDetails ? row.shipToDetails.split(',').map(detail => detail.trim()) : [],
//                   bankDetails: {
//                       bankName: row.bankName || "",
//                       accountNumber: row.accountNumber || "",
//                       ifsc: row.ifsc || "",
//                       accountHolder: row.accountHolder || ""
//                   },
//                   invoiceDate: isNaN(invoiceDate.getTime()) ? new Date() : invoiceDate, // Fallback to current date if invalid
//                   items: [{
//                       itemName: row.itemName || "",
//                       variety: row.variety || "",
//                       hsn: row.hsn || "",
//                       quantity: parseInt(row.quantity) || 0,
//                       price: parseFloat(row.price) || 0,
//                       unit: row.unit || "pcs"
//                   }]
//               };
//           });

//           setInvoices(allInvoices); // Set the invoices state
//       };
//       reader.readAsBinaryString(file);
//   } else {
//       alert('Please upload a valid CSV or Excel file.');
//   }
// };
const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;

    if (fileType === 'text/csv') {
        // Handle CSV file
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                const allInvoices = results.data.map((row) => {
                    // Basic validation
                    if (!row.invoiceDate || !row.itemNames) {
                        console.error("Missing required fields in CSV row:", row);
                        return null; // Skip this row
                    }

                    const invoiceDate = new Date(row.invoiceDate);
                    const itemNames = row.itemNames.split(',').map(item => item.trim());
                    const itemQuantities = row.itemQuantities ? row.itemQuantities.split(',').map(q => parseInt(q.trim())) : [];
                    const itemPrices = row.itemPrices ? row.itemPrices.split(',').map(p => parseFloat(p.trim())) : [];
                    const itemUnits = row.itemUnits ? row.itemUnits.split(',').map(u => u.trim()) : [];
                    const itemHsn = row.hsn ? row.hsn.split(',').map(h => h.trim()) : [];
                    const itemVariety = row.variety ? row.variety.split(',').map(v => v.trim()) : [];

                    const items = itemNames.map((name, index) => ({
                        itemName: name,
                        quantity: itemQuantities[index] || 0,
                        price: itemPrices[index] || 0,
                        unit: itemUnits[index] || "pcs",
                        hsn: itemHsn[index] || "",
                        variety: itemVariety[index] || ""
                    }));

                    return {
                        fromCompany: row.fromCompany || "",
                        fromDetails: row.fromDetails ? row.fromDetails.split('|').map(detail => detail.trim()) : [],
                        toCompany: row.toCompany || "",
                        toDetails: row.toDetails ? row.toDetails.split('|').map(detail => detail.trim()) : [],
                        shipToCompany: row.shipToCompany || "",
                        shipToDetails: row.shipToDetails ? row.shipToDetails.split('|').map(detail => detail.trim()) : [],
                        bankDetails: {
                            bankName: row.bankName || "",
                            accountNumber: row.accountNumber || "",
                            ifsc: row.ifsc || "",
                            accountHolder: row.accountHolder || ""
                        },
                        invoiceDate: isNaN(invoiceDate.getTime()) ? new Date() : invoiceDate,
                        items: items
                    };
                }).filter(invoice => invoice !== null); // Filter out null values

                setInvoices(allInvoices);
            },
            error: (error) => {
                console.error("Error reading CSV file:", error);
                alert("There was an error processing the CSV file. Please check the format and try again.");
            }
        });
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // Handle Excel file
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            const allInvoices = jsonData.map((row) => {
                // Basic validation
                if (!row.invoiceDate || !row.itemNames) {
                    console.error("Missing required fields in Excel row:", row);
                    return null; // Skip this row
                }

                const invoiceDate = new Date(row.invoiceDate);
                const itemNames = row.itemNames.split(',').map(item => item.trim());
                const itemQuantities = row.itemQuantities ? row.itemQuantities.split(',').map(q => parseInt(q.trim())) : [];
                const itemPrices = row.itemPrices ? row.itemPrices.split(',').map(p => parseFloat(p.trim())) : [];
                const itemUnits = row.itemUnits ? row.itemUnits.split(',').map(u => u.trim()) : [];
                const itemHsn = row.hsn ? row.hsn.split(',').map(h => h.trim()) : [];
                const itemVariety = row.variety ? row.variety.split(',').map(v => v.trim()) : [];

                const items = itemNames.map((name, index) => ({
                    itemName: name,
                    quantity: itemQuantities[index] || 0,
                    price: itemPrices[index] || 0,
                    unit: itemUnits[index] || "pcs",
                    hsn: itemHsn[index] || "",
                    variety: itemVariety[index] || ""
                }));

                return {
                    fromCompany: row.fromCompany || "",
                    fromDetails: row.fromDetails ? row.fromDetails.split('|').map(detail => detail.trim()) : [],
                    toCompany: row.toCompany || "",
                    toDetails: row.toDetails ? row.toDetails.split('|').map(detail => detail.trim()) : [],
                    shipToCompany: row.shipToCompany || "",
                    shipToDetails: row.shipToDetails ? row.shipToDetails.split('|').map(detail => detail.trim()) : [],
                    bankDetails: {
                        bankName: row.bankName || "",
                        accountNumber: row.accountNumber || "",
                        ifsc: row.ifsc || "",
                        accountHolder: row.accountHolder || ""
                    },
                    invoiceDate: isNaN(invoiceDate.getTime()) ? new Date() : invoiceDate,
                    items: items
                };
            }).filter(invoice => invoice !== null); // Filter out null values

            setInvoices(allInvoices);
        };

        reader.readAsBinaryString(file);
    } else {
        alert("Unsupported file type. Please upload a CSV or Excel file.");
    }
};
 

  const [companyList, setCompanyList] = useState([]);
  // Fetch company data from the server when the component mounts
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('http://localhost:5000/company'); // Adjust the URL if necessary
        setCompanyList(response.data); // Update companyList with fetched data
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanies();
  }, []); 
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get("http://localhost:5000/items"); // Adjust the URL if necessary
        setAvailableItems(response.data); // Update availableItems with fetched data
      } catch (error) {
        console.error("Error fetching items data:", error);
      }
    };

    fetchItems();
  }, []);
useEffect(() => {
    if (invoices.length > 0) {
        generatePDF(); // Call PDF generation when invoices are updated
    }
}, [invoices]);
  
  const [availableItems, setAvailableItems] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceNumber, setInvoiceNumber] = useState(generateInvoiceNumber());
  const [fromCompany, setFromCompany] = useState("");
  const [toCompany, setToCompany] = useState("");
  const [shipToCompany, setShipToCompany] = useState("");
  const [items, setItems] = useState([
    { itemName: "", quantity: "", hsn: "", price: 0, unit: "pcs" } // Added unit property
  ]);

  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    accountNumber: "",
    ifsc: "",
    accountHolder: ""
  });
  const [terms, setTerms] = useState("Payment due within 30 days. Late payments may incur a fee.");
  const [totalAmount, setTotalAmount] = useState(0);
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [shipToSuggestions, setShipToSuggestions] = useState([]);
  const [template, setTemplate] = useState("template1");
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const bankOptions = [
    {
      bankName: "Bank A",
      accountNumber: "123456789",
      ifsc: "BKID00 1234",
      accountHolder: "John Doe"
    },
    {
      bankName: "Bank B",
      accountNumber: "987654321",
      ifsc: "BKID00 5678",
      accountHolder: "Jane Doe"
    },
    {
      bankName: "Bank C",
      accountNumber: "564738291",
      ifsc: "BKID00 9012",
      accountHolder: "Bob Smith"
    }
  ];

  const templates = {
    template1: {
      className: "invoice-preview-template1",
      header: <h2 className="ti">Tax Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
    template2: {
      className: "invoice-preview-template2",
      header: <h2>Tax Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
    template3: {
      className: "invoice-preview-template3",
      header: <h2>Tax Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
  };
  
  const addItem = () => {
    setItems([...items, { itemName: "", quantity: "", hsn: "", price: 0, unit: "pcs" }]); // Include unit in new item
  };

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    const total = newItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalAmount(total);
  };

  const handleItemChange = (index, key, value, event) => {
    const newItems = [...items];
    
    // Update the specific field based on the key
    newItems[index][key] = value;
  
    // If the key is quantity, calculate the total without changing the price
    if (key === "quantity") {
      // Calculate the total amount for the item based on quantity and original price
      const totalPrice = newItems[index].price * value; // Use the original price
      newItems[index].totalPrice = totalPrice; // Store the total price if needed
    }
  
    setItems(newItems);
    
    // Calculate the total amount for the invoice based on all items
    const total = newItems.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    setTotalAmount(total);
  };
  function numberToWordsInIndianSystem(num) {
    if (num === 0) return "zero";

    const belowTwenty = [
        "","one","two","three","four","five","six","seven","eight","nine","ten","eleven","twelve","thirteen",
        "fourteen","fifteen","sixteen","seventeen","eighteen","nineteen"
  ];
    const tens = [
        "","","twenty","thirty","forty","fifty","sixty","seventy","eighty","ninety"
    ];
    const indianUnits = [
        "","thousand","lakh","crore"
    ];
    function helper(num) {
        if (num === 0) return "";
        else if (num < 20) return belowTwenty[num] + " ";
        else if (num < 100) return tens[Math.floor(num / 10)] + " " + helper(num % 10);
        else if (num < 1000) return belowTwenty[Math.floor(num / 100)] + " hundred " + helper(num % 100);
        else return ""; // Fallback to avoid undefined
    }

    let word = "";
    let i = 0;

    // Process the first three digits (thousands)
    if (num % 1000 !== 0) {
        word = helper(num % 1000) + indianUnits[i] + " ";
    }
    num = Math.floor(num / 1000);
    i++;

    // Process the remaining digits (lakhs and crores)
    while (num > 0) {
        if (num % 100 !== 0) {
            word = helper(num % 100) + indianUnits[i] + " " + word;
        }
        num = Math.floor(num / 100);
        i++;
    }

    return word.trim(); // Return the final result
}

// const generateInvoice = async (e) => {
//   e.preventDefault();
//   const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
//   setTotalAmount(total);
//   setInvoiceNumber(generateInvoiceNumber()); // Generate new invoice number

//   // Generate PDFs for each invoice and download them
//   await generateAndDownloadAllInvoices();
// };
// useEffect(() => {
//   if (invoices.length > 0) {
//       generateAndDownloadAllInvoices();
//   }
// }, [invoices]); // This will trigger whenever invoices change


// const generateAndDownloadAllInvoices = async () => {
//   // Create an array of promises for generating PDFs
//   const pdfPromises = invoices.map((invoice, index) => generatePDF(invoice, index));
  
//   try {
//       // Wait for all PDFs to be generated
//       await Promise.all(pdfPromises);
//   } catch (error) {
//       console.error("Error generating one or more PDFs:", error);
//   }
// };

// const generatePDF = (invoice, index) => {
//   return new Promise((resolve, reject) => {
//       // Create an element for the invoice
//       const input = document.getElementById(`invoice-output-${index}`);
//       if (!input) {
//           console.error(`Invoice element not found for index: ${index}`);
//           reject(new Error(`Invoice element not found for index: ${index}`));
//           return;
//       }

//       html2canvas(input, {
//           scale: 5,
//           useCORS: true,
//           logging: false
//       }).then((canvas) => {
//           const imgData = canvas.toDataURL("image/png");
//           const pdf = new jsPDF("p", "mm", "a4");
//           const pdfWidth = pdf.internal.pageSize.getWidth();
//           const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
//           pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
//           pdf.save(`invoice-${invoice.invoiceNumber || Date.now()}.pdf`); // Use invoice number if available
//           resolve(); // Resolve the promise when done
//       }).catch((error) => {
//           console.error("Error generating PDF:", error);
//           reject(error); // Reject the promise on error
//       });
//   });
// };
  // const generatePDF = () => {
  //   const input = document.getElementById("invoice-output");
  //   html2canvas(input, {
  //     scale: 5, // Increase the scale to improve the quality
  //     useCORS: true, // Allow cross-origin resource sharing
  //     logging: false // Disable logging
  //   }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //     pdf.save(`invoice-${Date.now()}.pdf`);
  //   });
  // };
// const generatePDF = () => {
//     // Ensure that the first invoice element exists
//     const input = document.getElementById("invoice-output-0"); // Adjust the index based on which invoice you want to generate

//     if (!input) {
//         console.error("Invoice element not found");
//         return;
//     }

//     html2canvas(input, {
//         scale: 5,
//         useCORS: true,
//         logging: false
//     }).then((canvas) => {
//         const imgData = canvas.toDataURL("image/png");
//         const pdf = new jsPDF("p", "mm", "a4");
//         const pdfWidth = pdf.internal.pageSize.getWidth();
//         const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
//         pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
//         pdf.save(`invoice-${Date.now()}.pdf`);
//     }).catch((error) => {
//         console.error("Error generating PDF:", error);
//     });
// };
  const filterSuggestions = (input, setSuggestions) => {
    const filtered = companyList.filter((company) =>
      company.name.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestions(filtered);
  };
  const [fromCompanyDetails, setFromCompanyDetails] = useState({
    address: "",
    gst: "",
    email: "",
    phone: ""
  });
  
  const handleFromCompanyChange = (e) => {
    const input = e.target.value;
    setFromCompany(input);
    filterSuggestions(input, setFromSuggestions);
  
    // Check if the input company is not in the company list
    if (!companyList.find((company) => company.name === input)) {
      // Add the new company to the company list
      setCompanyList([...companyList, {
        name: input,
        address: fromCompanyDetails.address,
        gst: fromCompanyDetails.gst,
        email: fromCompanyDetails.email,
        phone: fromCompanyDetails.phone
      }]);
    }
  };
  
  const handleFromCompanyDetailsChange = (e) => {
    const { name, value } = e.target;
    setFromCompanyDetails({ ...fromCompanyDetails, [name]: value });
  };
  
  const handleToCompanyChange = (e) => {
    const input = e.target.value;
    setToCompany(input);
    filterSuggestions(input, setToSuggestions);
  };

  const handleShipToCompanyChange = (e) => {
    const input = e.target.value;
    setShipToCompany(input);
    filterSuggestions(input, setShipToSuggestions);
  };

 
const formatDate = (date) => {
    if (!date || isNaN(date.getTime())) {
        return "Invalid date"; // Return a placeholder for invalid dates
    }
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString(undefined, options);
};
  // const formatDate = (date) => {
  //   const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //   return new Intl.DateTimeFormat("en-GB", options).format(date);
  // };

  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.match("image.*")) {
      alert("Please select an image file");
      return;
    }
    if (file.size > 1024 * 1024) {
      alert("Please select an image file with a size less than 1MB");
      return;
    }
    setLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="container">
      <h1>Bulk Invoice Generator</h1>
      <form onSubmit={generateInvoice} className="form">
      <div className="form-group">
          <label htmlFor="bulk-import">Bulk Import Items:</label>
          <input
            type="file"
            id="bulk-import"
            accept=".csv, .xlsx"
            onChange={handleFileChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="invoice-date">Invoice Date:</label>
          <div className="date-inputs">
            <input
              type="number"
              id="invoice-day"
              placeholder=" DD"
              value={invoiceDate.getDate() || ""}
              onChange={(e) => {
                const day = parseInt(e.target.value);
                const newDate = new Date(invoiceDate);
                newDate.setDate(day);
                setInvoiceDate(newDate);
              }}
              required
              min="1"
              max="31"
            />
            <input
              type="number"
              id="invoice-month"
              placeholder="MM"
              value={invoiceDate.getMonth() + 1 || ""}
              onChange ={(e) => {
                const month = parseInt(e.target.value) - 1; // Months are zero-indexed
                const newDate = new Date(invoiceDate);
                newDate.setMonth(month);
                setInvoiceDate(newDate);
              }}
              required
              min="1"
              max="12"
            />
            <input
              type="number"
              id="invoice-year"
              placeholder="YYYY"
              value={invoiceDate.getFullYear() || ""}
              onChange={(e) => {
                const year = parseInt(e.target.value);
                const newDate = new Date(invoiceDate);
                newDate.setFullYear(year);
                setInvoiceDate(newDate);
              }}
              required
              min="1900 "
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="logo">Logo:</label>
          <input
            type="file"
            id="logo"
            accept="image/*"
            onChange={handleLogoChange}
          />
          {logoPreview && (
            <img src={logoPreview} alt="Logo Preview" className="logo-preview" />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="from-company">My Company:</label>
          <input
            type="text"
            id="from-company"
            placeholder="Enter Company"
            value={fromCompany}
            onChange={handleFromCompanyChange}
            // required
          />
          {fromSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {fromSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setFromCompany(suggestion.name);
                    setFromSuggestions([]); // Hide suggestions on select
                    setFromCompanyDetails({
                      address: suggestion.address,
                      gst: suggestion.gst,
                      email: suggestion.email,
                      phone: suggestion.phone
                    });
                  }}
                >
                  <strong>{suggestion.name}</strong>
                  <br />
                  {suggestion.address}
                  <br />
                  GST: {suggestion.gst}
                  <br />
                  Email: {suggestion.email}
                  <br />
                  Phone: {suggestion.phone}
                </li>
              ))}
            </ul>
          )}
        </div> 
         <div className="form-group">
          <label htmlFor="to-company"> Bill To:</label>
          <input
            type="text"
            id="to-company"
            placeholder="Enter bill To Company"
            value={toCompany}
            onChange={handleToCompanyChange}
            // required
          />
          {toSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {toSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setToCompany(suggestion.name);
                    setToSuggestions([]); // Hide suggestions on select
                  }}
                > 
                  <strong>{suggestion.name}</strong>
                  <br />
                  {suggestion.address}
                  <br />
                  GST: {suggestion.gst}
                  <br />
                  Email: {suggestion.email}
                  <br />
                  Phone: {suggestion.phone}
                </li>
              ))}
            </ul>
          )}
        </div> 

        <div className="form-group">
          <label htmlFor="ship-to-company">Ship To:</label>
          <input
            type="text"
            id="ship-to-company"
            placeholder="Enter Ship To Company"
            value={shipToCompany}
            onChange={handleShipToCompanyChange}
          />
          {shipToSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {shipToSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setShipToCompany(suggestion.name);
                    setShipToSuggestions([]); // Hide suggestions on select
                  }}
                >
                  <strong>{suggestion.name}</strong>
                  <br />
                  {suggestion.address}
                  <br />
                  GST: {suggestion.gst}
                  <br />
                  Email: {suggestion.email}
                  <br />
                  Phone: {suggestion.phone}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* <div className="form-group">
        <label htmlFor="items">Items:</label>
        <div id="items">
          {items.map((item, index) => (
            <div key={index} className="item-row">
              <input
                type="text"
                className="item-input"
                placeholder="Enter Item Name"
                value={item.itemName}
                onChange={(e) => handleItemChange(index, "itemName", e.target.value, e)}
                // required
              />
              {item.itemName.length > 0 && (
                <ul className="suggestions-list">
                  {availableItems
                    .filter((availableItem) =>
                      availableItem.name.toLowerCase().includes(item.itemName.toLowerCase())
                    )
                    .map((availableItem, idx) => (
                      <li
                        key={idx}
                        onClick={() => {
                          handleItemChange(index, "itemName", availableItem.name, null);
                          const selectedHsn = availableItem.hsn;
                          const selectedPrice = availableItem.price;
                          const selectedVariety = availableItem.variety; // Get the variety

                          setItems((prevItems) => {
                            const newItems = [...prevItems];
                            newItems[index].hsn = selectedHsn;
                            newItems[index].price = selectedPrice;
                            newItems[index].variety = selectedVariety; // Set the variety
                            return newItems;
                          });
                        }}
                      >
                        {availableItem.name}
                      </li>
                    ))}
                </ul>
              )}
              <input
                type="text"
                id={`variety-${index}`} // Unique ID for accessibility
                className="item-input"
                placeholder="Variety"
                value={item.variety}
                onChange={(e) => handleItemChange(index, "variety", e.target.value, e)} // Handle variety change
              />

<input
      type="number"
      className="item-input"
      name="quantity"
      placeholder="Quantity"
      value={item.quantity}
      onChange={(e) => handleItemChange(index, "quantity", parseInt(e.target.value), e)}
      // required
    />
    <select
      value={item.unit}
      onChange={(e) => handleItemChange(index, "unit", e.target.value)}
      className="unit-select"
    >
      <option value="Box">Box</option>
      <option value="kg">kg</option>
      <option value="pcs">pcs</option>
      
    </select>
    <button type="button" className="btn-remove" onClick={() => removeItem(index)}>Remove</button>
  </div>
))}
            <button type="button" className="btn-add" onClick={addItem}>Add Item</button>
          </div>
        </div> */}

        <div className="form-group">
          <label htmlFor="bank-details">Bank Details:</label>
          <select
            id="bank-details"
            value={bankDetails.bankName}
            onChange={(e) => {
              const selectedBank = bankOptions.find((bank) => bank.bankName === e.target.value);
              setBankDetails({
                bankName: selectedBank.bankName,
                accountNumber: selectedBank.accountNumber,
                ifsc: selectedBank.ifsc,
                accountHolder: selectedBank.accountHolder
              });
            }}
            className="bank-select"
          >
            <option value="">Select Bank</option>
            {bankOptions.map((bank, index) => (
              <option key={index} value={bank.bankName}>
                {bank.bankName} - Account No: {bank.accountNumber}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="terms">Terms and Conditions:</label>
          <textarea
            id="terms"
            value={terms}
            onChange={(e) => setTerms(e.target.value)}
            rows="4"
            required
          />
        </div>

    

        <div className="form-group">
          <label htmlFor="template">Select Template:</label>
          <select
            id="template"
            value={template}
            onChange={handleTemplateChange}
            className="template-select"
          >
            <option value="template1">Template 1</option>
            <option value="template2">Template 2</option>
            <option value="template3">Template 3</option>
            
          </select>
        </div>

        <button type="submit" className="btn-submit">Generate Invoice and Download PDF</button>
      </form>
      {invoices.map((invoice, index) => (
    <div key={index} id={`invoice-output-${index}`} className={templates[template].className}>
        {templates[template].header}
        <div className="body">
          <div className="logo-container">
            {logoPreview ? (
              <img src={logoPreview} alt="Company Logo" className="logo" />
            ) : (
              <img src={logo} alt="Company Logo" className="logo" />
            )}
          </div>
          <div className="company">
            <p className="companyname"><strong>{invoice.fromCompany}</strong></p>
            <div className="agep">
              <p className="address"><span id="address">Address:</span><br></br>{invoice.fromDetails[0]}</p>
              <p className="gst"><span>GST:</span> {invoice.fromDetails[1]}</p>
              <p className="email"><span>Email:</span> {invoice.fromDetails[2]}
              <p className="phone"><span>Phone: </span>{invoice.fromDetails[3]}</p></p>
            </div>
          </div>
          <br></br>
          <div className="dbs">
            <div className="d">
              <h3 className="ind">Invoice details:</h3>
              <p className="invoice"><strong>Invoice No:</strong> {generateInvoiceNumber()}</p>
              <p className="date"><strong>Date:</strong> {formatDate(invoice.invoiceDate)}</p>
            </div>
            <div className="b">
              <h3 className="bt" id="bt">Bill To:</h3>
              <p><strong>{invoice.toCompany}</strong></p>
              <p>{invoice.toDetails[0]}</p>
              <p>GST: {invoice.toDetails[1]}</p>
              <p>Email: {invoice.toDetails[2]}</p>
              <p>Phone: {invoice.toDetails[3]}</p>
            </div>
            {invoice.shipToCompany && (
              <div className="s">
                <h3 className="st">Ship To:</h3>
                <p><strong>{invoice.shipToCompany}</strong></p>
                <p>{invoice.shipToDetails[0]}</p>
                <p>GST: {invoice.shipToDetails[1]}</p>
                <p>Email: {invoice.shipToDetails[2]}</p>
                <p>Phone: {invoice.shipToDetails[3]}</p>
              </div>
            )}
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Variety</th>
                  <th>Hsn</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {invoice.items.map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.itemName}</td>
                    <td>{item.variety}</td>
                    <td>{item.hsn}</td>
                    <td>{item.quantity}</td>
                    <td>{item.unit}</td>
                    <td>₹{item.price}</td>
                    <td>₹{(item.price * item.quantity).toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6} className="total">
                    <b>Total:</b>
                  </td>
                  <td>₹{invoice.items.reduce((acc, item) => acc + (item.price * item.quantity), 0).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="atw"><span className="taiw">Total Amount in Words: </span> {numberToWordsInIndianSystem(invoice.items.reduce((acc, item) => acc + (item.price * item.quantity), 0))} rupees</p>
          <div className="bankterm">
            <div className="bank">
              <h3 className="bd">Bank Details:</h3>
              <p className="bdp">
                <span>Bank Name :</span> {invoice.bankDetails.bankName}  <br />
                <span>Account No: </span>{invoice.bankDetails.accountNumber}
                <br />
                <span>IFSC:</span>{invoice.bankDetails.ifsc}
                <br />
                <span>Account Holder:</span> {invoice.bankDetails.accountHolder}
              </p>
 </div>
            <div className="t">
              <h3 className="terms">Terms:</h3>
              <p className="p">  {terms}</p>
            </div>
          </div>
          {templates[template].footer}
    </div>
      </div>
    ))}
  </div>
);

}; 

export default BulkImport;