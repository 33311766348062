// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import InvoiceGenerator from './InvoiceGenerator'; // Ensure this path is correct
import BulkImport from './BulkImport'; // Ensure this path is correct
import BulkV2 from './BulkV2';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<InvoiceGenerator />} />
        <Route path="/bulk-import" element={<BulkImport />} />
        <Route path="/bulkv2" element={<BulkV2/>}/>
      </Routes>
    </Router>
  );
};

export default App; // Ensure this is a default export