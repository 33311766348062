import React, { useState, useEffect } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import { Link } from 'react-router-dom';
import html2canvas from "html2canvas";
import "./InvoiceGenerator.css"; // Make sure to include your CSS file
// import logo from "./logo.png";
// import "./invoice.css";

const InvoiceGenerator = () => {
  const generateInvoiceNumber = () => {
    return `INV-${Math.floor(Math.random() * 90000) + 10000}`;
  };

  const [companyList, setCompanyList] = useState([
    // {
    //   name: "ABC Corp",
    //   address: "3/e,bharathiyar st,thiruvalluvar nager,medavakkam,tambaram-603202 ",
    //   gst: "GST1234567890",
    //   email: "abc.corp@example.com",
    //   phone: "123-456-7890"
    // },
    // {
    //   name: "XYZ Ltd",
    //   address: "456 Elm St, Othertown, USA",
    //   gst: "GST9876543210",
    //   email: "xyz.ltd@example.com",
    //   phone: "987-654-3210"
    // },
    // {
    //   name: "Tech Solutions",
    //   address: "789 Oak St, Techville, USA",
    //   gst: "GST1112223330",
    //   email: "tech.solutions@example.com",
    //   phone: "111-222-3330"
    // },
    // {
    //   name: "Global Ventures",
    //   address: "321 Pine St, Globalville, USA",
    //   gst: "GST4445556660",
    //   email: "global.ventures@example.com",
    //   phone: "444-555-6660"
    // },
    // {
    //   name: "Fast Delivery",
    //   address: "901 Maple St, Fastville, USA",
    //   gst: "GST7778889990",
    //   email: "fast.delivery@example.com",
    //   phone: "777-888-9990"
    // },
    // {
    //   name: "Quick Services",
    //   address: "234 Cedar St, Quickville, USA",
    //   gst: "GST6667778880",
    //   email: "quick.services@example.com",
    //   phone: "666-777-8880"
    // }
  ]);
  // Fetch company data from the server when the component mounts
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        // const response = await axios.get('http://localhost:5000/company'); // Adjust the URL if necessary
        const response = await axios.get('https://invoice-9fg7.onrender.com/company'); // Adjust the URL if necessary
       
        setCompanyList(response.data); // Update companyList with fetched data
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanies();
  }, []); 
  useEffect(() => {
    const fetchItems = async () => {
      try {
        // const response = await axios.get("http://localhost:5000/items"); // Adjust the URL if necessary
        const response = await axios.get("https://invoice-9fg7.onrender.com/items"); // Adjust the URL if necessary
       
        setAvailableItems(response.data); // Update availableItems with fetched data
      } catch (error) {
        console.error("Error fetching items data:", error);
      }
    };

    fetchItems();
  }, []);
  
  const [availableItems, setAvailableItems] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceNumber, setInvoiceNumber] = useState(generateInvoiceNumber());
  const [fromCompany, setFromCompany] = useState("");
  const [toCompany, setToCompany] = useState("");
  const [shipToCompany, setShipToCompany] = useState("");
  const [items, setItems] = useState([
    { itemName: "", quantity: "", hsn: "", price: 0, unit: "pcs" } // Added unit property
  ]);
  // const availableItems = [
  //   { name: "Apple", hsn: "0808", price: 2.00 },
  //   { name: "Mango", hsn: "0804", price: 1.50 },
  //   { name: "Banana", hsn: "0803", price: 1.00 },
  //   { name: "Orange", hsn: "0805", price: 1.25 },
  //   { name: "Grapes", hsn: "0806", price: 1.75 },
  //   // Add more items as needed
  // ];
  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    accountNumber: "",
    ifsc: "",
    accountHolder: ""
  });
  const [terms, setTerms] = useState("Payment due within 30 days. Late payments may incur a fee.");
  const [totalAmount, setTotalAmount] = useState(0);
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [shipToSuggestions, setShipToSuggestions] = useState([]);
  const [template, setTemplate] = useState("template1");
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const bankOptions = [
    {
      bankName: "Bank A",
      accountNumber: "123456789",
      ifsc: "BKID00 1234",
      accountHolder: "John Doe"
    },
    {
      bankName: "Bank B",
      accountNumber: "987654321",
      ifsc: "BKID00 5678",
      accountHolder: "Jane Doe"
    },
    {
      bankName: "Bank C",
      accountNumber: "564738291",
      ifsc: "BKID00 9012",
      accountHolder: "Bob Smith"
    }
  ];

  const templates = {
    template1: {
      className: "invoice-preview-template1",
      header: <h2 className="ti">Tax Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
    template2: {
      className: "invoice-preview-template2",
      header: <h2>Tax Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
    template3: {
      className: "invoice-preview-template3",
      header: <h2>Tax Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
  
  };


  
  const addItem = () => {
    setItems([...items, { itemName: "", quantity: "", hsn: "", price: 0, unit: "pcs" }]); // Include unit in new item
  };

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    const total = newItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalAmount(total);
  };

  const handleItemChange = (index, key, value, event) => {
    const newItems = [...items];
    
    // Update the specific field based on the key
    newItems[index][key] = value;
  
    // If the key is quantity, calculate the total without changing the price
    if (key === "quantity") {
      // Calculate the total amount for the item based on quantity and original price
      const totalPrice = newItems[index].price * value; // Use the original price
      newItems[index].totalPrice = totalPrice; // Store the total price if needed
    }
  
    setItems(newItems);
    
    // Calculate the total amount for the invoice based on all items
    const total = newItems.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    setTotalAmount(total);
  };
  function numberToWordsInIndianSystem(num) {
    if (num === 0) return "zero";

    const belowTwenty = [
        "","one","two","three","four","five","six","seven","eight","nine","ten","eleven","twelve","thirteen",
        "fourteen","fifteen","sixteen","seventeen","eighteen","nineteen"
    ];
    
    const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety"
    ];

    const indianUnits = [
        "",
        "thousand",
        "lakh",
        "crore"
    ];

    function helper(num) {
        if (num === 0) return "";
        else if (num < 20) return belowTwenty[num] + " ";
        else if (num < 100) return tens[Math.floor(num / 10)] + " " + helper(num % 10);
        else if (num < 1000) return belowTwenty[Math.floor(num / 100)] + " hundred " + helper(num % 100);
        else return ""; // Fallback to avoid undefined
    }

    let word = "";
    let i = 0;

    // Process the first three digits (thousands)
    if (num % 1000 !== 0) {
        word = helper(num % 1000) + indianUnits[i] + " ";
    }
    num = Math.floor(num / 1000);
    i++;

    // Process the remaining digits (lakhs and crores)
    while (num > 0) {
        if (num % 100 !== 0) {
            word = helper(num % 100) + indianUnits[i] + " " + word;
        }
        num = Math.floor(num / 100);
        i++;
    }

    return word.trim(); // Return the final result
}
const generateInvoice = (e) => {
  e.preventDefault();
  const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
  setTotalAmount(total);
  setInvoiceNumber(generateInvoiceNumber()); // Generate new invoice number
  generatePDF(); // Automatically generate PDF on invoice generation
};

const formatDateForFilename = (date) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
  return formattedDate.split('/').reverse().join('-'); // Convert to YYYY-MM-DD
};

const generatePDF = () => {
  const input = document.getElementById("invoice-output");
  html2canvas(input, {
      scale: 5, // Increase the scale to improve the quality
      useCORS: true, // Allow cross-origin resource sharing
      logging: false // Disable logging
  }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Create the filename using the company name, date, and invoice number
      const filename = `${toCompany}-${formatDateForFilename(invoiceDate)}-${invoiceNumber}.pdf`;
      pdf.save(filename);
  });
};
  // const generateInvoice = (e) => {
  //   e.preventDefault();
  //   const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
  //   setTotalAmount(total);
  //   setInvoiceNumber(generateInvoiceNumber()); // Generate new invoice number
  //   generatePDF(); // Automatically generate PDF on invoice generation
  // };

  // const generatePDF = () => {
  //   const input = document.getElementById("invoice-output");
  //   html2canvas(input, {
  //     scale: 5, // Increase the scale to improve the quality
  //     useCORS: true, // Allow cross-origin resource sharing
  //     logging: false // Disable logging
  //   }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //     pdf.save(`invoice-${Date.now()}.pdf`);
  //   });
  // };

  const filterSuggestions = (input, setSuggestions) => {
    const filtered = companyList.filter((company) =>
      company.name.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestions(filtered);
  };
  const [fromCompanyDetails, setFromCompanyDetails] = useState({
    address: "",
    gst: "",
    email: "",
    phone: ""
  });
  
  const handleFromCompanyChange = (e) => {
    const input = e.target.value;
    setFromCompany(input);
    filterSuggestions(input, setFromSuggestions);
  
    // Check if the input company is not in the company list
    if (!companyList.find((company) => company.name === input)) {
      // Add the new company to the company list
      setCompanyList([...companyList, {
        name: input,
        address: fromCompanyDetails.address,
        gst: fromCompanyDetails.gst,
        email: fromCompanyDetails.email,
        phone: fromCompanyDetails.phone
      }]);
    }
  };
  
  // const handleFromCompanyDetailsChange = (e) => {
  //   const { name, value } = e.target;
  //   setFromCompanyDetails({ ...fromCompanyDetails, [name]: value });
  // };
  
  const handleToCompanyChange = (e) => {
    const input = e.target.value;
    setToCompany(input);
    filterSuggestions(input, setToSuggestions);
  };

  const handleShipToCompanyChange = (e) => {
    const input = e.target.value;
    setShipToCompany(input);
    filterSuggestions(input, setShipToSuggestions);
  };

 

  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.match("image.*")) {
      alert("Please select an image file");
      return;
    }
    if (file.size > 1024 * 1024) {
      alert("Please select an image file with a size less than 1MB");
      return;
    }
    setLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="container">
      <h1>Invoice Generator</h1>
      <Link to="/bulk-import">
        <button className="bulk">Bulk Import</button>
      </Link>
      {/* <Link to="/BulkV2">
        <button className="bulkv2">Bulk Import-V2</button>
      </Link> */}
      <form onSubmit={generateInvoice} className="form">
        <div className="form-group">
          <label htmlFor="invoice-date">Invoice Date:</label>
          <div className="date-inputs">
            <input
              type="number"
              id="invoice-day"
              placeholder=" DD"
              value={invoiceDate.getDate() || ""}
              onChange={(e) => {
                const day = parseInt(e.target.value);
                const newDate = new Date(invoiceDate);
                newDate.setDate(day);
                setInvoiceDate(newDate);
              }}
              required
              min="1"
              max="31"
            />
            <input
              type="number"
              id="invoice-month"
              placeholder="MM"
              value={invoiceDate.getMonth() + 1 || ""}
              onChange ={(e) => {
                const month = parseInt(e.target.value) - 1; // Months are zero-indexed
                const newDate = new Date(invoiceDate);
                newDate.setMonth(month);
                setInvoiceDate(newDate);
              }}
              required
              min="1"
              max="12"
            />
            <input
              type="number"
              id="invoice-year"
              placeholder="YYYY"
              value={invoiceDate.getFullYear() || ""}
              onChange={(e) => {
                const year = parseInt(e.target.value);
                const newDate = new Date(invoiceDate);
                newDate.setFullYear(year);
                setInvoiceDate(newDate);
              }}
              required
              min="1900 "
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="logo" >Logo:</label>
          <input
            type="file"
            id="logo"
            accept="image/*"
            onChange={handleLogoChange}
          />
          {logoPreview && (
            <img src={logoPreview} alt="Logo Preview" className="logo-preview" />
          )}
        </div>
       <div className="form-group">
          <label htmlFor="from-company">My Company:</label>
          <input
            type="text"
            id="from-company"
            placeholder="Enter Company"
            value={fromCompany}
            onChange={handleFromCompanyChange}
            required
          />
          {fromSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {fromSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setFromCompany(suggestion.name);
                    setFromSuggestions([]); // Hide suggestions on select
                    setFromCompanyDetails({
                      address: suggestion.address,
                      gst: suggestion.gst,
                      email: suggestion.email,
                      phone: suggestion.phone
                    });
                  }}
                >
                  <strong>{suggestion.name}</strong>
                  <br />
                  {suggestion.address}
                  <br />
                  GST: {suggestion.gst}
                  <br />
                  Email: {suggestion.email}
                  <br />
                  Phone: {suggestion.phone}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="to-company"> Bill To:</label>
          <input
            type="text"
            id="to-company"
            placeholder="Enter bill To Company"
            value={toCompany}
            onChange={handleToCompanyChange}
            required
          />
          {toSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {toSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setToCompany(suggestion.name);
                    setToSuggestions([]); // Hide suggestions on select
                  }}
                > 
                  <strong>{suggestion.name}</strong>
                  <br />
                  {suggestion.address}
                  <br />
                  GST: {suggestion.gst}
                  <br />
                  Email: {suggestion.email}
                  <br />
                  Phone: {suggestion.phone}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="ship-to-company">Ship To:</label>
          <input
            type="text"
            id="ship-to-company"
            placeholder="Enter Ship To Company"
            value={shipToCompany}
            onChange={handleShipToCompanyChange}
          />
          {shipToSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {shipToSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setShipToCompany(suggestion.name);
                    setShipToSuggestions([]); // Hide suggestions on select
                  }}
                >
                  <strong>{suggestion.name}</strong>
                  <br />
                  {suggestion.address}
                  <br />
                  GST: {suggestion.gst}
                  <br />
                  Email: {suggestion.email}
                  <br />
                  Phone: {suggestion.phone}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* <div className="form-group">
        <label htmlFor="items">Items:</label>
        <div id="items">
          {items.map((item, index) => (
            <div key={index} className="item-row">
              <input
                type="text"
                className="item-input"
                placeholder="Enter Item Name"
                value={item.itemName}
                onChange={(e) => handleItemChange(index, "itemName", e.target.value, e)}
                required
              />
              {item.itemName.length > 0 && (
                <ul className="suggestions-list">
                  {availableItems
                    .filter((availableItem) =>
                      availableItem.name.toLowerCase().includes(item.itemName.toLowerCase())
                    )
                    .map((availableItem, idx) => (
                      <li
                        key={idx}
                        onClick={() => {
                          handleItemChange(index, "itemName", availableItem.name, null);
                          const selectedHsn = availableItem.hsn;
                          const selectedPrice = availableItem.price;
                          const selectedVariety = availableItem.variety; // Get the variety

                          setItems((prevItems) => {
                            const newItems = [...prevItems];
                            newItems[index].hsn = selectedHsn;
                            newItems[index].price = selectedPrice;
                            newItems[index].variety = selectedVariety; // Set the variety
                            return newItems;
                          });
                        }}
                      >
                        {availableItem.name}
                      </li>
                    ))}
                </ul>
              )}
              <input
                type="text"
                id={`variety-${index}`} // Unique ID for accessibility
                className="item-input"
                placeholder="Variety"
                value={item.variety}
                onChange={(e) => handleItemChange(index, "variety", e.target.value, e)} // Handle variety change
              />

<input
      type="number"
      className="item-input"
      name="quantity"
      placeholder="Quantity"
      value={item.quantity}
      onChange={(e) => handleItemChange(index, "quantity", parseInt(e.target.value), e)}
      required
    />
    <select
      value={item.unit}
      onChange={(e) => handleItemChange(index, "unit", e.target.value)}
      className="unit-select"
    >
      <option value="Box">Box</option>
      <option value="kg">kg</option>
      <option value="pcs">pcs</option>
      
    </select>
    <button type="button" className="btn-remove" onClick={() => removeItem(index)}>Remove</button>
  </div>
))}
            <button type="button" className="btn-add" onClick={addItem}>Add Item</button>
          </div>
        </div> */}
<div className="form-group">
  <label htmlFor="items">Items:</label>
  <div id="items">
    {items.map((item, index) => (
      <div key={index} className="item-row">
        <input
          type="text"
          className="item-input"
          placeholder="Enter Item Name"
          value={item.itemName}
          onChange={(e) => handleItemChange(index, "itemName", e.target.value, e)}
          required
        />
        {item.itemName.length > 0 && (
          <ul className="suggestions-list">
          {availableItems
  .filter((availableItem) =>
    availableItem.name && item.itemName && 
    availableItem.name.toLowerCase().includes(item.itemName.toLowerCase())
  )
  .map((availableItem, idx) => (
    <li
      key={idx}
      onClick={() => {
        handleItemChange(index, "itemName", availableItem.name, null);
        const selectedHsn = availableItem.hsn;
        const selectedPrice = availableItem.price;
        const selectedVariety = availableItem.variety; // Get the variety

        setItems((prevItems) => {
          const newItems = [...prevItems];
          newItems[index].hsn = selectedHsn;
          newItems[index].price = selectedPrice;
          newItems[index].variety = selectedVariety; // Set the variety
          return newItems;
        });
      }}
    >
      {availableItem.name}
    </li>
              ))}
          </ul>
        )}
            <input
                type="text"
                id={`variety-${index}`} // Unique ID for accessibility
                className="item-input"
                placeholder="Variety"
                value={item.variety}
                onChange={(e) => handleItemChange(index, "variety", e.target.value, e)} // Handle variety change
              />
   

        <input
          type="number"
          className="item-input"
          name="quantity"
          placeholder="Quantity"
          value={item.quantity}
          onChange={(e) => handleItemChange(index, "quantity", parseInt(e.target.value), e)}
          required
        />
        
        <select
          value={item.unit}
          onChange={(e) => handleItemChange(index, "unit", e.target.value)}
          className="unit-select"
        >
          <option value="Box">Box</option>
          <option value="kg">kg</option>
          <option value="pcs">pcs</option>
          {/* Add more unit options as needed */}
        </select>
        
        <button type="button" className="btn-remove" onClick={() => removeItem(index)}>Remove</button>
      </div>
    ))}
    <button type="button" className="btn-add" onClick={addItem}>Add Item</button>
  </div>
</div>
        <div className="form-group">
          <label htmlFor="bank-details">Bank Details:</label>
          <select
            id="bank-details"
            value={bankDetails.bankName}
            onChange={(e) => {
              const selectedBank = bankOptions.find((bank) => bank.bankName === e.target.value);
              setBankDetails({
                bankName: selectedBank.bankName,
                accountNumber: selectedBank.accountNumber,
                ifsc: selectedBank.ifsc,
                accountHolder: selectedBank.accountHolder
              });
            }}
            className="bank-select"
          >
            <option value="">Select Bank</option>
            {bankOptions.map((bank, index) => (
              <option key={index} value={bank.bankName}>
                {bank.bankName} - Account No: {bank.accountNumber}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="terms">Terms and Conditions:</label>
          <textarea
            id="terms"
            value={terms}
            onChange={(e) => setTerms(e.target.value)}
            rows="4"
            required
          />
        </div>

    

        <div className="form-group">
          <label htmlFor="template">Select Template:</label>
          <select
            id="template"
            value={template}
            onChange={handleTemplateChange}
            className="template-select"
          >
            <option value="template1">Template 1</option>
            <option value="template2">Template 2</option>
            <option value="template3">Template 3</option>
            {/* <option value="template4">Template 4</option> */}
          </select>
        </div>

        <button type="submit" className="btn-submit">Generate Invoice and Download PDF</button>
      </form>

      <div id="invoice-output" className={templates[template].className}>
        {templates[template].header}
        <div className="body">
          <div className="logo-container">
            {logoPreview ? (
              <img src={logoPreview} alt="Company Logo" className="logo" />
            ) : (
              <img src={logo} alt="Company Logo" className="logo" />
            )}
          </div>
          <div className="company">
  <p className="companyname"><strong>{fromCompany}</strong></p>
  <div className="agep">
    <p className="address"><span id="address">Address:</span><br></br>{fromCompanyDetails.address}</p>
    <p className="gst"><span>GST:</span> {fromCompanyDetails.gst}</p>
    <p className="email"><span>Email:</span> {fromCompanyDetails.email}
    <p className="phone"><span>Phone: </span>{fromCompanyDetails.phone}</p>  </p>
  </div>
</div>
          <br></br>
          <div className="dbs">
            <div className="d">
              <h3 className="ind">Invoice details:</h3>

              <p className="invoice"><strong>Invoice No:</strong> {invoiceNumber}</p>
              <p className="date"><strong>Date:</strong> {formatDate(invoiceDate)}</p>
            </div>
{/* <div className="btst"> */}
            <div className="b">
              <h3 className="bt">Bill To:</h3>

              <p><strong>{toCompany}</strong></p>
              {companyList.find((company) => company.name === toCompany) && (
                <div>
                  <p>{companyList.find((company) => company.name === toCompany).address}</p>
                  <p>GST: {companyList.find((company) => company.name === toCompany).gst}</p>
                  <p>Email: {companyList.find ((company) => company.name === toCompany).email}</p>
                  <p>Phone: {companyList.find((company) => company.name === toCompany).phone}</p>
                </div>
              )}
            </div>
            <div className="s">
              {shipToCompany && (
                <div>
                  <h3 className="st">Ship To:</h3>
                  <p><strong>{shipToCompany}</strong></p>
                  {companyList.find((company) => company.name === shipToCompany) && (
                    <div>
                      <p>{companyList.find((company) => company.name === shipToCompany).address}</p>
                      <p>GST: {companyList.find((company) => company.name === shipToCompany).gst}</p>
                      <p>Email: {companyList.find((company) => company.name === shipToCompany).email}</p>
                      <p>Phone: {companyList.find((company) => company.name === shipToCompany).phone}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          {/* </div> */}
          </div>
<div>
<table>
  <thead>
    <tr>
      <th>Item Name</th>
      <th>Variety</th> {/* New column for Variety */}
      <th>Hsn</th>
      <th>Quantity</th>
      <th>Unit</th>
      <th>Price</th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody>
  {items.map((item, index) => (
    <tr key={index}>
      <td>{item.itemName}</td>
      <td>{item.variety}</td> {/* Display variety here */}
      <td>{item.hsn}</td>
      <td>{item.quantity}</td>
      <td>{item.unit}</td>
      <td>₹{item.price}</td>
      <td>₹{(item.price * item.quantity).toFixed(2)}</td>
    </tr>
  ))}
    <tr>
      <td colSpan={6} className="total">
        <b>Total:</b>
      </td>
      <td>₹{totalAmount.toFixed(2)}</td>
    </tr>
  </tbody>
</table>
     </div>
          <p className="atw"><span className="taiw">Total Amount in Words: </span> {numberToWordsInIndianSystem(totalAmount)} rupees</p>
          <div className="bankterm">
            <div className="bank">
              <h3 className="bd">Bank Details:</h3>
              <p className="bdp">
                <span>Bank Name :</span> {bankDetails.bankName}  <br />
                <span>Account No: </span>{bankDetails.accountNumber}
                <br />
                <span>IFSC:</span>{bankDetails.ifsc}
                <br />
                <span>Account Holder:</span> {bankDetails.accountHolder}
              </p>
            </div>
            <div className="t">
              <h3 className="terms">Terms:</h3>
              <p className="p">  {terms}</p>
            </div>

          </div>
          {templates[template].footer}
          {/* <text className="tfyb"> Thanking you for your business </text> */}

        </div>

      </div>

    </div>
  );
};

export default InvoiceGenerator;